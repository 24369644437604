var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editVoucherModal),callback:function ($$v) {_vm.editVoucherModal=$$v},expression:"editVoucherModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Voucher")]),_c('v-card-text',[_c('v-text-field',{staticClass:"flex-grow-0",staticStyle:{"margin-bottom":"-4px","width":"220px"},attrs:{"label":"Maximum Usage Count","outlined":"","dense":"","type":"number","min":1},model:{value:(_vm.maxUsageCount),callback:function ($$v) {_vm.maxUsageCount=_vm._n($$v)},expression:"maxUsageCount"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.maxUsageCount},on:{"click":_vm.editVoucher}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.editVoucherModal = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientVoucherPage.docs,"options":_vm.options,"server-items-length":_vm.clientVoucherPage.total,"no-data-text":"No gift vouchers to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher.name)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.date,"D/MM/YYYY"))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher.type)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.value)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","outlined":"","rounded":""},on:{"click":function($event){_vm.voucher = item;
          _vm.maxUsageCount = item.maxUsageCount;
          _vm.editVoucherModal = true;}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }